import { Layout } from '@components/layout';
import { PageHeader } from '@components/shared';
import { ArticlesList } from '@components/standard';
import { IPage, IPostPage, IPost, ILocation, ICategory } from '@standardTypes';
import React from 'react';

interface KnowledgePageProps {
  pageContext: {
    page: IPage | IPostPage | IPost;
    posts: IPost[];
    categories: ICategory;
  };
  location: ILocation;
}

const descPlaceholder = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at
venenatis egestas amet. Vitae aliquam blandit arcu, amet urna,
tincidunt. Pellentesque velit eget diam adipiscing neque, elit arcu.`;

const KnowledgePage: React.FC<KnowledgePageProps> = ({
  pageContext,
  location
}) => {
  const { page, posts, categories } = pageContext;
  const { search } = location;

  return (
    <Layout {...page}>
      <PageHeader title="Wiedza" description={descPlaceholder} />
      <ArticlesList
        posts={posts}
        pageParam={search}
        subcategories={categories}
      />
    </Layout>
  );
};

export default KnowledgePage;
